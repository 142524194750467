<template>
  <el-container class="layout-container">
      <el-aside width="15%">
        <div class="el-aside__logo"></div>
            <el-menu active-text-color="#000000" background-color="#aaaaaa"  text-color="#fff"
                router >
              <el-menu-item index="/House">
                  <el-icon>
                    <House/>
                  </el-icon>
                  <span>主页</span>
              </el-menu-item>
              <el-sub-menu index="0">
                <template #title>
                  <el-icon>
                    <Document/>
                  </el-icon>
                  <span>招商资质登记</span>
                </template>
               <el-menu-item index="/shop/shopBaseInfo" v-if="userInfo.roleCode=='manager'||userInfo.roleCode=='Investment'||userInfo.roleCode=='invStaff'||userInfo.roleCode=='admin'||userInfo.roleCode=='JDinvStaff'">
                  <el-icon>
                    <Management/>
                  </el-icon>
                  <span>京东登记</span>
              </el-menu-item>
                <el-menu-item index="/mtshop/shopBaseInfo" v-if="userInfo.roleCode=='manager'||userInfo.roleCode=='Investment'||userInfo.roleCode=='invStaff'||userInfo.roleCode=='admin'||userInfo.roleCode=='JDinvStaff'">
                  <el-icon>
                    <Management/>
                  </el-icon>
                  <span>美团登记</span>
                </el-menu-item>
              </el-sub-menu>
              <el-sub-menu index="1">
                <template #title>
                  <el-icon>
                    <Document/>
                  </el-icon>
                  <span>订单管理</span>
                </template>
                <el-menu-item index="/order/orderBaseInfo" v-if="userInfo.roleCode=='manager'||userInfo.roleCode=='admin'|| userInfo.roleCode=='Operation'||userInfo.roleCode=='Investment'||userInfo.roleCode=='invStaff'||userInfo.roleCode=='csrService'||userInfo.roleCode=='finance'||userInfo.roleCode=='opeStaff'">
                  <el-icon> 
                    <Document/>
                  </el-icon>
                  <span>京东订单登记</span>
                </el-menu-item>
                <el-menu-item index="/order/TBorderBaseInfo" v-if="userInfo.roleCode=='manager'
                                                                    ||userInfo.roleCode=='admin'
                                                                    || userInfo.roleCode=='Operation'
                                                                    ||userInfo.roleCode=='TBopeStaff'
                                                                    ||userInfo.roleCode=='finance'
                                                                    ||userInfo.roleCode=='Investment' ">
                  <el-icon>
                    <Document/>
                  </el-icon>
                  <span>淘宝订单登记</span>
                </el-menu-item>
                <el-menu-item index="/order/MTorderBaseInfo" v-if="userInfo.roleCode=='manager'
                                                                    ||userInfo.roleCode=='admin'
                                                                    || userInfo.roleCode=='Operation'
                                                                    ||userInfo.roleCode=='finance'
                                                                    ||userInfo.roleCode=='MTopeStaff'
                                                                    ||userInfo.roleCode=='Investment'">
                  <el-icon>
                    <Document/>
                  </el-icon>
                  <span>美团订单登记</span>
                </el-menu-item>

                <!-- <el-menu-item>
                  <el-icon>
                    <Crop />
                  </el-icon>
                  <span>登记数据统计</span>
                </el-menu-item> -->
              </el-sub-menu>

              <el-sub-menu index="2">
                <template #title>
                  <el-icon>
                    <UserFilled />
                  </el-icon>
                  <span>员工管理</span>
                </template>
                <el-menu-item>
                  <el-icon> 
                    <User />
                  </el-icon>
                  <span>员工列表</span>
                </el-menu-item>
                <el-menu-item >
                  <el-icon>
                    <Crop />
                  </el-icon>
                  <span>员工登记</span>
                </el-menu-item>
                <el-menu-item >
                  <el-icon>
                    <EditPen />
                  </el-icon>
                  <span>权限管理</span>
                </el-menu-item>
              </el-sub-menu>
              <el-sub-menu index="3">
                <template #title>
                  <el-icon>
                    <Tools />
                  </el-icon>
                  <span>辅助插件</span>
                </template>
                <el-menu-item>
                  <el-icon>
                    <User />
                  </el-icon>
                  <span>京东账号分配</span>
                </el-menu-item>
                <el-menu-item index="/plugin/pluginBaseInfo" v-if="userInfo.roleCode === 'admin' || userInfo.roleCode === 'manager' || userInfo.roleCode=='Operation'">
                  <el-icon>
                    <Crop />
                  </el-icon>
                  <span>京东账号管理</span>
                </el-menu-item>
                <el-menu-item>
                  <el-icon>
                    <EditPen />
                  </el-icon>
                  <span>招商信息读取</span>
                </el-menu-item>
              </el-sub-menu>
              <el-menu-item index="/log/BaseLog" v-if="userInfo.roleCode === 'admin' || userInfo.roleCode === 'manager'">
                <el-icon>
                  <Management/>
                </el-icon>
                <span>日志管理</span>
              </el-menu-item>
            </el-menu>
      </el-aside>
    <el-container>
      <el-header style="height: 100px;">
          <div id="homeTitle">小辣后台管理系统</div>
          <el-dropdown placement="bottom-end">
            <span class="el-dropdown__box">
              <el-avatar :src="avatar" />
              <strong style="margin-left: 5px;color: #000;">{{ userInfo.realname }}</strong>
              <el-icon>
                <CaretBottom/>
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="profile" :icon="User">基本资料</el-dropdown-item>
                <el-dropdown-item command="avatar" :icon="Crop">更换头像</el-dropdown-item>
                <el-dropdown-item command="password" :icon="EditPen">重置密码</el-dropdown-item>
                <el-dropdown-item command="logout" :icon="SwitchButton" @click="logout()">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
      </el-header>
      <el-main>
        <router-view :userInfo="userInfo"></router-view>
      </el-main>
      <!-- 底部区域 -->
    </el-container>
  </el-container>
</template>

<script setup>
import {
  House,
  Management,
  Document,
  UserFilled,
  Tools,
  Crop,
  EditPen,
  SwitchButton,
  CaretBottom,
  User
} from '@element-plus/icons-vue'
import { logoutService } from '@/api/user';
import { useRouter } from 'vue-router';
import avatar from '@/assets/default.png'
import {ref} from 'vue'
import { ElMessage } from 'element-plus';
import { useTokenStore } from '@/store/token';

const userInfo=ref({
  "realname":'',
  "username":'',
  "roleCode":'',
})

const router = useRouter();
const logout=()=>{
  logoutService()
  useTokenStore().removeToken();
  localStorage.removeItem("username")
  ElMessage.success("账号已注销")
  router.push("/login")
}


const info=()=>{
  userInfo.value.realname=sessionStorage.getItem('realname')
  userInfo.value.username=sessionStorage.getItem('username')
  userInfo.value.roleCode=sessionStorage.getItem('roleCode')
} 
info()

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
  body{
    margin: 0%;
  }
  .el-header{
    // display: flex;
    // align-items: center;
    // flex-direction: row-reverse;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // box-shadow: 0 0 10px black;
    

    .el-dropdown__box {
      display: flex;
      align-items: center;

      .el-icon {
        color: #999;
        margin-left: 10px;
      }

      &:active,
      &:focus {
        outline: none;
      }
    }
  }
  


.layout-container {
  height: 100vh;

  .el-aside {
    background-color: #aaaaaa;

    &__logo {
        height: 120px;
        // background: url('@/assets/logo.png') no-repeat center / 120px auto;
    }

    .el-menu {
        border-right: none;
    }

    width: 12%;
  }
}
  .el-footer {
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #666;
  }
  #homeTitle{
    color: #000000;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 30px;
  }
</style>
