<template>
  <div class="box">
    <el-dialog v-model="visibleDrawer" title="美团登记订单" width="700px" >
      <el-form ref="form" :model="checkInfo" label-width="auto" style="max-width: 700px">
        <el-form-item label="订单号">
          <el-input v-model="checkInfo.orderId" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="商家名称">
          <el-input v-model="checkInfo.venderId" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select v-model="checkInfo.orderStatus">
            <el-option label="待处理" value="41000"></el-option>
            <el-option label="订单取消" value="20020"></el-option>
            <el-option label="订单完成" value="90000"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-input v-model="checkInfo.orderStartTime" placeholder="YYYY-MM-DD"></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="checkInfo.skuName" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="客户名">
          <el-input v-model="checkInfo.buyerFullName" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input v-model="checkInfo.buyerFullAddress" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="买家备注">
          <el-input type="textarea" v-model="checkInfo.orderBuyerRemark" :rows="5"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="thirdPhone">
          <el-input v-model="checkInfo.thirdPhone" placeholder="" :disabled="orderControll.thirdPhone" rules="thirdPhone" ></el-input>
        </el-form-item>
        <el-form-item label="供货商" prop="thirdSuppliers">
          <el-input v-model="checkInfo.thirdSuppliers" placeholder="" :disabled="orderControll.thirdSuppliers"></el-input>
        </el-form-item>
        <el-form-item label="串码" prop="upcCode">
          <el-input v-model="checkInfo.upcCode" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="配送方式" prop="thirdCarrierName">
          <el-select v-model="checkInfo.thirdCarrierName" :disabled="orderControll.thirdCarrierName">
            <el-option label="快递" value="快递"></el-option>
            <el-option label="闪送" value="闪送"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态" prop="thirdOrderStatus">
          <el-select v-model="checkInfo.thirdOrderStatus" :disabled="orderControll.thirdOrderStatus">
            <el-option label="刷单" value="刷单"></el-option>
            <el-option label="取消" value="取消"></el-option>
            <el-option label="完成" value="完成"></el-option>
            <el-option label="售后退款" value="售后退款"></el-option>
            <el-option label="微信退款" value="微信退款"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单取消原因">
          <el-select v-model="checkInfo.thirdOrderCancelRemark" :disabled="orderControll.thirdOrderCancelRemark">
            <el-option label="无" value="无"></el-option>
            <el-option label="缺货" value="缺货"></el-option>
            <el-option label="价格问题" value="价格问题"></el-option>
            <el-option label="配送问题" value="配送问题"></el-option>
            <el-option label="时间问题" value="时间问题"></el-option>
            <el-option label="客户秒取消" value="客户秒取消"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="checkInfo.remark" :rows="5"></el-input>
        </el-form-item>
        <el-form-item label="登记人" prop="upcCode">
          <el-input v-model="checkInfo.checkUser" placeholder=""></el-input>
        </el-form-item>

        <el-row :gutter="44">
          <el-col :span="10" style="padding-right: 0px;">
            <el-form-item label="本单预计收入" prop="actualMoneyDouble">
              <el-input type="input"  :disabled="orderControll.actualMoneyDouble" v-model="checkInfo.actualMoneyDouble" @blur="profit()"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <span style="font-size: 24px; text-align: center;">￥</span>
          </el-col>
          <el-col :span="10" style="padding-right: 0px;">
            <el-form-item label="拿货价" prop="purchaseMoneyDouble">
              <el-input type="input" :disabled="orderControll.purchaseMoneyDouble" v-model="checkInfo.purchaseMoneyDouble" @blur="profit()"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <span style="font-size: 24px;">￥</span>
          </el-col>
        </el-row>

        <el-row :gutter="44">
          <el-col :span="10" style="padding-right: 0px;">
            <el-form-item label="运费金额" prop="freightMoneyDouble">
              <el-input type="input"  :disabled="orderControll.freightMoneyDouble" v-model="checkInfo.freightMoneyDouble" @blur="profit()"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <span style="font-size: 24px; text-align: center;">￥</span>
          </el-col>
          <el-col :span="10" style="padding-right: 0px;">
            <el-form-item label="客户退补差价" prop="retrieveMoney">
              <el-input type="input"  :disabled="orderControll.retrieveMoney" v-model="checkInfo.retrieveMoney" @blur="profit()"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <span style="font-size: 24px;">￥</span>
          </el-col>
        </el-row>

        <el-form-item label="本单毛利:">
          <span>{{ checkInfo.profitMoneyDouble }}</span>
          <span style="font-size: 24px;">￥</span>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <!-- 由于客户退还，本单预计收入已作废，实际毛利为当时成交时（预计收入-拿货价）亏损的部分毛利+厂家要求退货额外需要的金额 -->
          <el-button type="primary" @click="add()" >
            登记
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref,defineProps } from 'vue'
import {
  addOfflineOrderMt, selectOrderCheckIn
} from '@/api/order.js';
import { ElMessage } from 'element-plus';
const visibleDrawer = ref(false)
import big from 'big.js';
import dayjs from "dayjs";
const form=ref(null)

const open=()=>{
  visibleDrawer.value=true
}
defineExpose({open})


const profit=()=>{
  if(checkInfo.value.purchaseMoneyDouble!=''&&checkInfo.value.actualMoneyDouble!=''){
    checkInfo.value.profitMoneyDouble=big(checkInfo.value.actualMoneyDouble).minus(checkInfo.value.purchaseMoneyDouble)
        .minus(checkInfo.value.freightMoneyDouble).plus(checkInfo.value.retrieveMoney).toFixed(2)
  }else{
    checkInfo.value.profitMoneyDouble='0.00'
  }
}
/**
 * 表单顶部数据回显
 */
const orderIdDataResult=ref({
  orderId:'',
  buyerFullName: '',
  buyerFullAddress: '',
  torderProductList: [
    {
      skuName: '',
      skuJdPrice: '',
      skuCount: ''
    }
  ],
  hasCheckInData:'',
  afterMarket:'',
  afterMoney:'',
  retrieveRemark:'',
  retrieveMoney:''
})


const checkInfo=ref({
  orderId:'',
  venderId:'',
  orderStatus:'',
  orderStartTime:dayjs().format('YYYY-MM-DD'),
  orderPreEndDeliveryTime:'',
  buyerFullName:'',
  buyerFullAddress:'',
  orderBuyerRemark:'',
  orderBuyerPayableMoney:'',
  settlementAmount:'',
  skuName:'',
  skuId:'',
  skuJdPrice:'',
  skuCount:'',
  type:'3',
  thirdPhone:'',
  thirdSuppliers:'',
  thirdCarrierName:'',
  thirdOrderStatus:'',
  thirdOrderCancelRemark:'',
  remark:'',
  profitMoneyDouble:'',
  purchaseMoneyDouble:'',
  actualMoneyDouble:'',
  upcCode:'',
  afterMarket:'',
  afterReason:'',
  retrieveRemark:'',
  retrieveMoney:'0',
  afterMoney:'0',
  freightMoneyDouble:'0'
})


const add=async()=>{
  if(checkInfo.value.orderId==''){
    ElMessage.error("请输入订单号！");
    return false;
  }
  if(checkInfo.value.venderId==''){
    ElMessage.error("请输入商家名称！");
    return false;
  }
  if(checkInfo.value.orderStatus==''){
    ElMessage.error("请选择订单状态！");
    return false;
  }

  if(checkInfo.value.orderStartTime==''){
    ElMessage.error("请输入下单时间！");
    return false;
  }
  if(checkInfo.value.skuName==''){
    ElMessage.error("请输入商品名称！");
    return false;
  }
  if(checkInfo.value.orderStatus!='20020'&&checkInfo.value.thirdOrderStatus!='刷单') {
    if (checkInfo.value.buyerFullName == '') {
      ElMessage.error("请输入客户名！");
      return false;
    }
    // if(checkInfo.value.buyerFullAddress==''){
    //   ElMessage.error("请输入详细地址！");
    //   return false;
    // }
    if (checkInfo.value.thirdPhone == '') {
      ElMessage.error("请输入电话！");
      return false;
    } else {
      if (/^1[3-9]\d{9}$/.test(checkInfo.value.thirdPhone) == false) {
        ElMessage.error('电话号码不符合规范');
        return false;
      }
    }
  }
  if(checkInfo.value.actualMoneyDouble==''){
    ElMessage.error("请输入本单预计收入！");
    return false;
  }else{
    if(/^(-)?[0-9]+([.][0-9]{1,2})?$/.test(checkInfo.value.actualMoneyDouble)==false){
      ElMessage.error('请输入正确的预计收入金额');
      return false;
    }
  }

  if(checkInfo.value.purchaseMoneyDouble==''){
    ElMessage.error("请输入拿货价！");
    return false;
  }else{
    if(/^(-)?[0-9]+([.][0-9]{1,2})?$/.test(checkInfo.value.purchaseMoneyDouble)==false){
      ElMessage.error('请输入正确的拿货价金额');
      return false;
    }
  }

  if(checkInfo.value.freightMoneyDouble==''){
    ElMessage.error("请输入运费！");
    return false;
  }else{
    if(/^(-)?[0-9]+([.][0-9]{1,2})?$/.test(checkInfo.value.freightMoneyDouble)==false){
      ElMessage.error('请输入正确的运费金额');
      return false;
    }
  }

  if(checkInfo.value.retrieveMoney==''){
    ElMessage.error("请输入客户退补差价！");
    return false;
  }else{
    if(/^(-)?[0-9]+([.][0-9]{1,2})?$/.test(checkInfo.value.retrieveMoney)==false){
      ElMessage.error('请输入正确的客户退补差价金额');
      return false;
    }
  }

  checkInfo.value.orderStartTime=checkInfo.value.orderStartTime+" 00:00:00";
  checkInfo.value.orderPreEndDeliveryTime=checkInfo.value.orderStartTime;
  let result = await addOfflineOrderMt(checkInfo.value)

  if(result.code=='200'){
    ElMessage.success("添加成功")
    visibleDrawer.value=false
    //  location.reload()
    clear()
  }else{
    checkInfo.value.orderStartTime.replace(" 00:00:00","");
  }
}
const clear=()=>{
  checkInfo.value.orderId='';
  checkInfo.value.venderId='';
  checkInfo.value.orderStatus='';
  checkInfo.value.orderStartTime='';
  checkInfo.value.orderPreEndDeliveryTime='';
  checkInfo.value.buyerFullName='';
  checkInfo.value.buyerFullAddress='';
  checkInfo.value.orderBuyerRemark='';
  checkInfo.value.orderBuyerPayableMoney='';
  checkInfo.value.settlementAmount='';
  checkInfo.value.skuName='';
  checkInfo.value.skuId='';
  checkInfo.value.skuJdPrice='';
  checkInfo.value.skuCount='';
  checkInfo.value.thirdPhone='';
  checkInfo.value.thirdSuppliers='';
  checkInfo.value.thirdCarrierName='';
  checkInfo.value.thirdOrderStatus='';
  checkInfo.value.thirdOrderCancelRemark='';
  checkInfo.value.remark='';
  checkInfo.value.profitMoneyDouble='';
  checkInfo.value.purchaseMoneyDouble='';
  checkInfo.value.actualMoneyDouble='';
  checkInfo.value.upcCode='';
  checkInfo.value.afterMarket='';
  checkInfo.value.afterReason='';
  checkInfo.value.retrieveRemark='';
  checkInfo.value.retrieveMoney='0';
  checkInfo.value.afterMoney='0';
  checkInfo.value.freightMoneyDouble='0';
};

/**
 * 表单组件控制
 */
const orderControll=ref({
  thirdPhone:false,
  thirdSuppliers:false,
  thirdOrderStatus:false,
  thirdCarrierName:false,
  thirdOrderCancelRemark:false,
  remark:false,
  actualMoneyDouble:false,
  purchaseMoneyDouble:false,
  upcCode:false,
  hasCheckInData:false,
  after:false,
  retrieve:false
})


const afterData=ref({
  orderId:'',
  thirdPhone:'',
  thirdSuppliers:'',
  thirdCarrierName:'',
  thirdOrderStatus:'',
  thirdOrderCancelRemark:'',
  remark:'',
  profitMoneyDouble:'',
  purchaseMoneyDouble:'',
  actualMoneyDouble:'',
  upcCode:'',
  afterMarket:'',
  afterReason:'',
  retrieveRemark:'',
  retrieveMoney:'',
  afterMoney:''
})

const afterView=()=>{
  afterData.value.orderId=checkInfo.value.orderId
  afterData.value.thirdPhone=checkInfo.value.thirdPhone
  afterData.value.thirdSuppliers=checkInfo.value.thirdSuppliers
  afterData.value.thirdCarrierName=checkInfo.value.thirdCarrierName
  afterData.value.thirdOrderStatus=checkInfo.value.thirdOrderStatus
  afterData.value.thirdOrderCancelRemark=checkInfo.value.thirdOrderCancelRemark
  afterData.value.profitMoneyDouble=checkInfo.value.profitMoneyDouble
  afterData.value.purchaseMoneyDouble=checkInfo.value.purchaseMoneyDouble
  afterData.value.actualMoneyDouble=checkInfo.value.actualMoneyDouble
  afterData.value.upcCode=checkInfo.value.upcCode

  afterData.value.afterMarket=checkInfo.value.afterMarket
  afterData.value.afterReason=checkInfo.value.afterReason
  afterData.value.retrieveRemark=checkInfo.value.retrieveRemark
  afterData.value.retrieveMoney=checkInfo.value.retrieveMoney
  afterData.value.afterMoney=checkInfo.value.afterMoney
  infotableShow.value.open()
}
const retrieveView=()=>{
  afterData.value.orderId=checkInfo.value.orderId
  afterData.value.thirdPhone=checkInfo.value.thirdPhone
  afterData.value.thirdSuppliers=checkInfo.value.thirdSuppliers
  afterData.value.thirdCarrierName=checkInfo.value.thirdCarrierName
  afterData.value.thirdOrderStatus=checkInfo.value.thirdOrderStatus
  afterData.value.thirdOrderCancelRemark=checkInfo.value.thirdOrderCancelRemark
  afterData.value.profitMoneyDouble=checkInfo.value.profitMoneyDouble
  afterData.value.purchaseMoneyDouble=checkInfo.value.purchaseMoneyDouble
  afterData.value.actualMoneyDouble=checkInfo.value.actualMoneyDouble
  afterData.value.upcCode=checkInfo.value.upcCode

  afterData.value.id=checkInfo.value.orderId
  afterData.value.afterMarket=checkInfo.value.afterMarket
  afterData.value.afterReason=checkInfo.value.afterReason
  afterData.value.retrieveRemark=checkInfo.value.retrieveRemark
  afterData.value.retrieveMoney=checkInfo.value.retrieveMoney
  afterData.value.afterMoney=checkInfo.value.afterMoney
  retrieveShow.value.open()
}
</script>