import request from '@/utils/request.js'
export const selectOrderInfoPage=(orderQueryDTO)=>{
    var url='/api/order/selectOrderInfoPage?';
    url=url+"pageNo="+orderQueryDTO.pageNo+"&pageSize="+orderQueryDTO.pageSize;
    if(orderQueryDTO.shopName!=""&&orderQueryDTO.shopName!==undefined){
        url=url+"&shopName="+orderQueryDTO.shopName
    }
    if(orderQueryDTO.orderId!=""&&orderQueryDTO.orderId!==undefined){
        url=url+"&orderId="+orderQueryDTO.orderId
    }
    if(orderQueryDTO.orderStatus!=""&&orderQueryDTO.orderStatus!==undefined){
        url=url+"&orderStatus="+orderQueryDTO.orderStatus
    }
    if(orderQueryDTO.startDeliveryTime!=""&&orderQueryDTO.startDeliveryTime!==undefined){
        url=url+"&startDeliveryTime="+orderQueryDTO.startDeliveryTime
    }
    if(orderQueryDTO.endDeliveryTime!=""&&orderQueryDTO.endDeliveryTime!==undefined){
        url=url+"&endDeliveryTime="+orderQueryDTO.endDeliveryTime
    }
    if(orderQueryDTO.checkStatus!=""&&orderQueryDTO.checkStatus!==undefined){
        url=url+"&checkIn="+orderQueryDTO.checkStatus
    }
    if(orderQueryDTO.areaId!=""&&orderQueryDTO.areaId!==undefined){
        url=url+"&areaId="+orderQueryDTO.areaId
    }
    if(orderQueryDTO.type!=""&&orderQueryDTO.type!==undefined){
        url=url+"&type="+orderQueryDTO.type
    }
    return request.get(url)
}

export const selectOrderStatus=()=>{
    return request.get('/api/orderStatusType/selectOrderStatus')
}

export const selectOrderCheckIn=(orderId)=>{
    return request.get('/api/checkIn/selectOrderCheckIn?orderId='+orderId)
}

export const addOrderCheckIn=(checkInfo)=>{
    return request.post('/api/checkIn/addOrderCheckIn',checkInfo)
}

export const queryProfitTotal=(profitQuery)=>{
    var url='/api/order/queryProfitTotal?type='+profitQuery.type;

    if(profitQuery.startTime!=""){
        url=url+"&startTime="+profitQuery.startTime
    }
    if(profitQuery.endTime !== ""){
        url=url+"&endTime="+profitQuery.endTime
    }
    if(profitQuery.shopName !== ''){
        url=url+"&shopName="+profitQuery.shopName
    }
    if(profitQuery.areaId!=""&&profitQuery.areaId!==undefined){
        url=url+"&areaId="+profitQuery.areaId
    }
    return request.get(url)
}

export const delErrOrder = (TShopInfo) => {
    return request.post('/api/order/delErrOrder', TShopInfo)
}

export const exportOrderCheckInData = () => {
    return request.get('/api/order/exportOrderCheckInData')
}

export const updateOrderCheckIn=(orderQueryDTO)=>{
    return request.post('/api/checkIn/updateOrderCheckIn',orderQueryDTO)
}

export const updateMTorTBOrder=(orderQueryDTO)=>{
    return request.post('/api/checkIn/updateMTorTBOrder',orderQueryDTO)
}

export const afterMarketCheck=(afterMarketData)=>{
    return request.post('/api/checkIn/afterMarketCheck',afterMarketData)
}

export const afterMarketEcho=(id)=>{
    return request.get('/api/checkIn/afterMarketEcho&id='+id)
}

export const addOfflineOrder=(checkInfo)=>{
    return request.post('/api/order/addOfflineOrder',checkInfo)
}

export const addOfflineOrderMt=(checkInfo)=>{
    return request.post('/api/checkIn/addOfflineOrderMt',checkInfo)
}

export const imageUpload = (checkInfo) => {
    return request.post('/api/image/upload', checkInfo,{
        headers: {
            'Content-Type':'multipart/form-data'
        }
    });
};