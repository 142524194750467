<template>
    <div class="box">
    <el-dialog v-model="visibleDrawer" title="登记订单" width="700px" @opened="returnData()">
        <el-row :gutter="20">
            <el-col :span="4"><div class="grid-content bg-purple">客户名: {{ orderIdDataResult.buyerFullName }}</div></el-col>
            <el-col :span="15"><div class="grid-content bg-purple">收货地址: {{ orderIdDataResult.buyerFullAddress }}</div></el-col>
        </el-row>
        <el-table :data="orderIdDataResult.torderProductList" height="160" border style="width: 100%">
            <el-table-column prop="skuName" label="商品名称"></el-table-column>
            <el-table-column prop="skuJdPrice" label="实付金额 (单位:元)" width="150"></el-table-column>
            <el-table-column prop="skuCount" label="数量" width="60"></el-table-column>
        </el-table>
        <el-divider/>
        <el-form ref="form" :model="checkInfo" label-width="auto" style="max-width: 600px">
            <el-form-item label="电话" prop="thirdPhone">
                <el-input v-model="checkInfo.thirdPhone" placeholder="" :disabled="orderControll.thirdPhone"></el-input>
            </el-form-item>
            <el-form-item label="供货商" prop="thirdSuppliers">
                <el-input v-model="checkInfo.thirdSuppliers" placeholder="" :disabled="orderControll.thirdSuppliers"></el-input>
            </el-form-item>
            <el-form-item label="串码" prop="upcCode">
                <el-input v-model="checkInfo.upcCode" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="配送方式" prop="thirdCarrierName">
                <el-select v-model="checkInfo.thirdCarrierName" :disabled="orderControll.thirdCarrierName">
                <el-option label="快递" value="快递"></el-option>
                <el-option label="闪送" value="闪送"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订单状态" prop="thirdOrderStatus">
                <el-select v-model="checkInfo.thirdOrderStatus" :disabled="orderControll.thirdOrderStatus">
                <el-option label="刷单" value="刷单"></el-option>
                <el-option label="取消" value="取消"></el-option>
                <el-option label="完成" value="完成"></el-option>
                <el-option label="售后退款" value="售后退款"></el-option>
                <el-option label="微信退款" value="微信退款"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订单取消原因">
                <el-select v-model="checkInfo.thirdOrderCancelRemark" :disabled="orderControll.thirdOrderCancelRemark">
                    <el-option label="无" value="无"></el-option>
                    <el-option label="缺货" value="缺货"></el-option>
                    <el-option label="价格问题严重薅羊毛" value="价格问题严重薅羊毛"></el-option>
                    <el-option label="价格问题差几十做不起" value="价格问题差几十做不起"></el-option>
                    <el-option label="客户着急要时效达不到" value="客户着急要时效达不到"></el-option>
                    <el-option label="客户下单秒取消" value="客户下单秒取消"></el-option>
                    <el-option label="客户拒收" value="客户拒收"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注">
                <el-input type="textarea" v-model="checkInfo.remark" :rows="3"></el-input>
            </el-form-item>
            <el-form-item label="凭证图片">
              <el-upload
                  :on-change="handlePreview"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :auto-upload="false"
                  :headers="Headers"
                  :multiple="true"
                  :limit="3"
                  :disabled="isUploadDisabled"
                  style="width: 600px">
                <el-button size="small" type="primary">选取图片</el-button>
              </el-upload>

            </el-form-item>
            <el-form-item label="登记人" prop="upcCode">
                <el-input v-model="checkInfo.checkUser" placeholder=""></el-input>
            </el-form-item>

            <el-row :gutter="44">
                <el-col :span="10" style="padding-right: 0px;">
                    <el-form-item label="本单预计收入" prop="actualMoneyDouble">
                        <el-input type="input"  :disabled="orderControll.actualMoneyDouble" v-model="checkInfo.actualMoneyDouble" @blur="profit()"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="1">
                    <span style="font-size: 24px; text-align: center;">￥</span>
                </el-col>
                <el-col :span="10" style="padding-right: 0px;">
                    <el-form-item label="拿货价" prop="purchaseMoneyDouble">
                        <el-input type="input" :disabled="orderControll.purchaseMoneyDouble" v-model="checkInfo.purchaseMoneyDouble" @blur="profit()"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="1">
                    <span style="font-size: 24px;">￥</span>
                </el-col>
            </el-row>

            <el-row :gutter="44">
                <el-col :span="10" style="padding-right: 0px;">
                    <el-form-item label="售后退货金额" prop="afterMoney">
                        {{ checkInfo.afterMoney }}
                    </el-form-item>
                </el-col>
                <el-col :span="1">
                    <span style="font-size: 24px; text-align: center;">￥</span>
                </el-col>
                <el-col :span="10" style="padding-right: 0px;">
                    <el-form-item label="客户退补差价" prop="retrieveMoney">
                        {{ checkInfo.retrieveMoney }}
                    </el-form-item>
                </el-col>
                <el-col :span="1">
                    <span style="font-size: 24px;">￥</span>
                </el-col>
            </el-row>

            <el-form-item label="本单毛利:">
                <span>{{ checkInfo.profitMoneyDouble }}</span>
                <span style="font-size: 24px;">￥</span>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="afterView()" :disabled="orderControll.after">
                售后单登记
                </el-button>
                <!-- 由于客户退还，本单预计收入已作废，实际毛利为当时成交时（预计收入-拿货价）亏损的部分毛利+厂家要求退货额外需要的金额 -->
                <el-button type="primary" @click="retrieveView()" :disabled="orderControll.retrieve">
                退补差价登记
                </el-button>

                <el-button type="primary" @click="add()" v-if="orderIdDataResult.hasCheckInData==0">
                登记
                </el-button>
                <el-button type="primary" @click="update()" v-if="orderIdDataResult.hasCheckInData==1">
                修改
                </el-button>
            </div>
        </template>
    </el-dialog>
    </div>
    <orderAfterMarket ref="infotableShow" :afterData="afterData"></orderAfterMarket>
    <orderRetrieve ref="retrieveShow" :afterData="afterData"></orderRetrieve>
</template>

<script setup>
import { ref,defineProps } from 'vue'
import {
  selectOrderCheckIn,
  addOrderCheckIn,
  updateOrderCheckIn, imageUpload
} from '@/api/order.js';
import big from 'big.js';
import { ElMessage } from 'element-plus';
import orderAfterMarket from './orderAfterMarket.vue'
import orderRetrieve from './orderRetrieve.vue'

const visibleDrawer = ref(false)

const infotableShow=ref();
const retrieveShow=ref();

const form=ref(null)

const isUploadDisabled = ref(false);

const open=()=>{
    visibleDrawer.value=true
}

const props = defineProps ({
    orderIdData : String
})
const afterData=ref({
    orderId:'',
    thirdPhone:'',
    thirdSuppliers:'',
    thirdCarrierName:'',
    thirdOrderStatus:'',
    thirdOrderCancelRemark:'',
    remark:'',
    profitMoneyDouble:'',
    purchaseMoneyDouble:'',
    actualMoneyDouble:'',
    upcCode:'',
    afterMarket:'',
    afterReason:'',
    retrieveRemark:'',
    retrieveMoney:'',
    afterMoney:''
})

const afterView=()=>{
    afterData.value.orderId=checkInfo.value.orderId
    afterData.value.thirdPhone=checkInfo.value.thirdPhone
    afterData.value.thirdSuppliers=checkInfo.value.thirdSuppliers
    afterData.value.thirdCarrierName=checkInfo.value.thirdCarrierName
    afterData.value.thirdOrderStatus=checkInfo.value.thirdOrderStatus
    afterData.value.thirdOrderCancelRemark=checkInfo.value.thirdOrderCancelRemark
    afterData.value.profitMoneyDouble=checkInfo.value.profitMoneyDouble
    afterData.value.purchaseMoneyDouble=checkInfo.value.purchaseMoneyDouble
    afterData.value.actualMoneyDouble=checkInfo.value.actualMoneyDouble
    afterData.value.upcCode=checkInfo.value.upcCode

    afterData.value.afterMarket=checkInfo.value.afterMarket
    afterData.value.afterReason=checkInfo.value.afterReason
    afterData.value.retrieveRemark=checkInfo.value.retrieveRemark
    afterData.value.retrieveMoney=checkInfo.value.retrieveMoney
    afterData.value.afterMoney=checkInfo.value.afterMoney
    infotableShow.value.open()
}
const retrieveView=()=>{
    afterData.value.orderId=checkInfo.value.orderId
    afterData.value.thirdPhone=checkInfo.value.thirdPhone
    afterData.value.thirdSuppliers=checkInfo.value.thirdSuppliers
    afterData.value.thirdCarrierName=checkInfo.value.thirdCarrierName
    afterData.value.thirdOrderStatus=checkInfo.value.thirdOrderStatus
    afterData.value.thirdOrderCancelRemark=checkInfo.value.thirdOrderCancelRemark
    afterData.value.profitMoneyDouble=checkInfo.value.profitMoneyDouble
    afterData.value.purchaseMoneyDouble=checkInfo.value.purchaseMoneyDouble
    afterData.value.actualMoneyDouble=checkInfo.value.actualMoneyDouble
    afterData.value.upcCode=checkInfo.value.upcCode

    afterData.value.id=checkInfo.value.orderId
    afterData.value.afterMarket=checkInfo.value.afterMarket
    afterData.value.afterReason=checkInfo.value.afterReason
    afterData.value.retrieveRemark=checkInfo.value.retrieveRemark
    afterData.value.retrieveMoney=checkInfo.value.retrieveMoney
    afterData.value.afterMoney=checkInfo.value.afterMoney
    retrieveShow.value.open()
}

/**
 * 表单顶部数据回显
 */
const orderIdDataResult=ref({
    orderId:'',
    buyerFullName: '',
    buyerFullAddress: '',
    torderProductList: [
        {
            skuName: '',
            skuJdPrice: '',
            skuCount: ''
        }
    ],
    hasCheckInData:'',
    afterMarket:'',
    afterMoney:'',
    retrieveRemark:'',
    retrieveMoney:''
})

/**
 * 表单组件控制
 */
const orderControll=ref({
    thirdPhone:true,
    thirdSuppliers:true,
    thirdOrderStatus:true,
    thirdCarrierName:true,
    thirdOrderCancelRemark:true,
    remark:true,
    actualMoneyDouble:true,
    purchaseMoneyDouble:true,
    upcCode:true,
    hasCheckInData:true,
    after:true,
    retrieve:true
})

/**
 * 接口入参与回显
 */
const checkInfo=ref({
    orderId:'',
    thirdPhone:'',
    thirdSuppliers:'',
    thirdCarrierName:'',
    thirdOrderStatus:'',
    thirdOrderCancelRemark:'',
    remark:'',
    profitMoneyDouble:'',
    purchaseMoneyDouble:'',
    actualMoneyDouble:'',
    upcCode:'',
    afterMarket:'',
    afterReason:'',
    afterMoney:'',
    retrieveRemark:'',
    retrieveMoney:'',
    images:''
})

const returnData=async()=>{
    orderIdDataResult.value=props.orderIdData
    var username=localStorage.getItem("username")
    console.log(orderIdDataResult.value.hasCheckInData)
    if(orderIdDataResult.value.hasCheckInData==0||username=='gaodan'){
        orderControll.value.thirdPhone=false
        orderControll.value.thirdSuppliers=false
        orderControll.value.thirdCarrierName=false
        orderControll.value.thirdOrderStatus=false
        orderControll.value.thirdOrderCancelRemark=false
        orderControll.value.remark=false
        orderControll.value.purchaseMoneyDouble=false
        orderControll.value.actualMoneyDouble=false
        orderControll.value.upcCode=false
        orderControll.value.retrieve=false
        isUploadDisabled.value=false;
    }else{
        orderControll.value.thirdPhone=true
        orderControll.value.thirdSuppliers=true
        orderControll.value.thirdOrderStatus=true
        orderControll.value.thirdCarrierName=true
        orderControll.value.thirdOrderCancelRemark=true
        orderControll.value.remark=true
        orderControll.value.actualMoneyDouble=true
        orderControll.value.purchaseMoneyDouble=true
        orderControll.value.upcCode=true
        orderControll.value.hasCheckInData=true
        orderControll.value.images=false
        isUploadDisabled.value=true;
    }
    if(orderIdDataResult.value.hasCheckInData==1){
        if(orderIdDataResult.value.afterMarket==1&&orderIdDataResult.value.retrieveRemark==0){
            orderControll.value.after=false
        }else if(orderIdDataResult.value.afterMarket==0&&orderIdDataResult.value.retrieveRemark==1){
            orderControll.value.retrieve=false
        }else{
            orderControll.value.after=false
            orderControll.value.retrieve=false
        }
    }else{
        orderControll.value.after=true
    }
    clear()
    let result = await selectOrderCheckIn(orderIdDataResult.value.orderId)
    checkInfo.value=result.data
    checkInfo.value.orderId=orderIdDataResult.value.orderId
    checkInfo.value.actualMoneyDouble=result.data.actualMoneyView
    checkInfo.value.purchaseMoneyDouble=result.data.purchaseMoneyView
    checkInfo.value.profitMoneyDouble=result.data.profitMoneyView
    checkInfo.value.afterMoney=result.data.afterMoney/100
    checkInfo.value.retrieveMoney=result.data.retrieveMoney/100
    if(checkInfo.value.profitMoneyDouble==''){
        checkInfo.value.profitMoneyDouble='0.00'
    }
  if(result.data.images=='') {
    fileList.value = [];
  }else{
    fileList.value = [];
    // 将图片链接转换为 fileList 格式
    const links = result.data.images.split(',');
    fileList.value = links.map((link, index) => ({
      name: `图片-${index + 1}`, // 文件名
      url: link, // 图片链接
      uid: index + 1, // 文件唯一标识
      status: 'success' // 文件状态
    }));
  }

}



const profit=()=>{
    if(checkInfo.value.purchaseMoneyDouble!=''&&checkInfo.value.actualMoneyDouble!=''){
        checkInfo.value.profitMoneyDouble=big(checkInfo.value.actualMoneyDouble).minus(checkInfo.value.purchaseMoneyDouble).toFixed(2)
    }else{
        checkInfo.value.profitMoneyDouble='0.00'
    }
}

const add=async()=>{
      //判断是否有图片，先执行图片上传
    if(fileList.value!=null&&fileList.value.length>0){
      checkInfo.value.images='';
      const formData = new FormData();
      fileList.value.forEach((file) => {
        formData.append('images', file);
      });
      console.log(formData);
      let result = await imageUpload(formData);
      console.log(result);
      if(result.code =='200') {
        ElMessage.success("上传成功")
        result.data.forEach((url) => {
          if(checkInfo.value.images ==''){
            checkInfo.value.images=url;
          }else{
            checkInfo.value.images+=","+url;
          }
        });
      }
    }
    console.log(checkInfo.value.images);
    let result = await addOrderCheckIn(checkInfo.value)
    if(result.code=='200'){
        ElMessage.success("添加成功")
        visibleDrawer.value=false
        // location.reload()
        clear()
    }
}

const update=async()=>{
    let result = await updateOrderCheckIn(checkInfo.value)
    if(result.code=='200'){
        ElMessage.success("修改成功")
        visibleDrawer.value=false
        location.reload()
        clear()
    }
}

const fileList = ref([]);

const uploadImages=async ()=>{
  console.log(fileList.value);
  checkInfo.value.images='';
  const formData = new FormData();
  fileList.value.forEach((file) => {
    formData.append('images', file);
  });
  console.log(formData);
  let result = await imageUpload(formData);
  console.log(result);
  if(result.code =='200') {
    ElMessage.success("上传成功")
    result.data.forEach((url) => {
      if(checkInfo.value.images ==''){
        checkInfo.value.images=url;
      }else{
        checkInfo.value.images+=","+url;
      }
    });
  }else{
    ElMessage.error("上传失败")
  }
};

const handlePreview= (file) => {
  console.log(file);
  fileList.value.push(file.raw);
}

const handleRemove=(file)  =>{
  console.log(file);
  const fileNameToDelete = file.raw.name; // 假设根据文件名来删除
  // const index = fileList.value.indexOf(file);
  const index = fileList.value.findIndex((file) => file.name === fileNameToDelete);
  if (index!== -1) {
    fileList.value.splice(index, 1);
  }
  console.log("handleRemove=",fileList);
}

// 显示放大图片的方法
const showLargeImage = (url) => {
  this.selectedImage = url;
};

// 隐藏放大图片的方法
const closeModal = () => {
  this.selectedImage = null;
};


const clear=()=>{
    checkInfo.value.thirdPhone='';
    checkInfo.value.thirdSuppliers='';
    checkInfo.value.thirdCarrierName='';
    checkInfo.value.thirdOrderStatus='';
    checkInfo.value.thirdOrderCancelRemark='';
    checkInfo.value.remark='';
    checkInfo.value.actualMoney='';
    checkInfo.value.estimateMoney='';
    checkInfo.value.profitMoneyDouble='';
    checkInfo.value.images='';
}
defineExpose({open})

// =========================

const checkPhone=(rule,value,callback)=>{
    if(/^1[3-9]\d{9}$/.test(value)==false){
        callback(new Error('电话号码不符合规范'))
    }
}

const money=(rule,value,callback)=>{
    if(/^(-)?[0-9]+([.][0-9]{1,2})?$/.test(value)==false){
        callback(new Error('请输入正确的金额'))
    }
}

const checkValue=(rule,value,callback)=>{
    if(value==''){
        callback(new Error('请输入正确的金额'))
    }
}

const rules={
    thirdPhone:[
        {required:true, message:"请输入电话", trigger:'blur'},
        {validator:checkPhone ,trigger:'blur'}
    ],
    thirdSuppliers:[
        {required:true, message:"请输入供货商", trigger:'blur'}
    ],
    purchaseMoneyDouble:[
        {required:true, message:"请输入拿货价", trigger:'blur'},
        {validator:money ,trigger:'blur'}
    ],
    actualMoneyDouble:[
        {required:true, message:"请输入预计收入", trigger:'blur'},
        {validator:money ,trigger:'blur'}
    ]
}

</script>
<style lang="scss" scoped>
.box {
    :deep(){
        .el-dialog__header {
            line-height: 1px;
            text-align: left;
            height: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ccc;
            margin-bottom: 30px;
        }
    }
}

.avatar-uploader {
    :deep() {
        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }

        .el-upload {
            border: 1px dashed var(--el-border-color);
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            transition: var(--el-transition-duration-fast);
        }

        .el-upload:hover {
            border-color: var(--el-color-primary);
        }

        .el-icon.avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            text-align: center;
        }
    }
}
.el-form{
    .el-input{
        width: 570px;
    }
    .el-select{
        width: 570px;
    }
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
  }
/* 缩略图样式 */
.thumbnail-list {
  display: flex;
  gap: 20px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid #ddd;
  border-radius: 4px;
  transition: transform 0.2s;
}

.thumbnail:hover {
  transform: scale(1.05);
}

/* 模态框样式 */
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  position: relative;
  padding: 20px;
  background: white;
  border-radius: 8px;
}

.large-image {
  max-width: 80vw;
  max-height: 80vh;
  object-fit: contain;
}

.close-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: white;
  border: 1px solid #ddd;
  cursor: pointer;
  font-size: 20px;
}

/* 过渡效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>